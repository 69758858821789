<template>
  <div class="container">
    <div class="container-body">
        <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
        <el-row>
            <el-col :span="6">
                <div v-if="ClientWidth_number > 850" class="grid-content bg-purple title" style="height:5vw;width:120px;">
                    <h3>安全咨询</h3>
                </div>
                <div v-else class="grid-content bg-purple title" style="height:10vw;width:30vw;">
                    <h3>安全咨询</h3>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>赛尔安全咨询服务专业团队和资深专家依托全面的知识体系和丰富的行业经验，依据国际/国内标准和行业监管规范，协助各行业客户立足于实际现状，面向信息安全风险，采取适当的管理过程和控制措施，建立和维护全面、有效、合规的信息安全管理体系，整体提升信息安全管理成熟度，保障业务运营和战略达成。安全咨询服务主要包含：</p>
                </div>
            </el-col>
        </el-row>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>信息系统安全风险评估服务    对客户的重要信息系统，识别和评估信息资产的重要性、安全威胁的可能性、安全脆弱性的严重程度、以及安全控制措施的有效性等要素，对重要信息系统所面临的信息安全风险进行识别和定性评估，并对所有评估发现的不可接受风险给出对应的安全处置和加固建议，协助客户提升对重要信息系统的安全风险管理和安全保障能力。赛尔安全在银行业客户电子银行业务系统安全评估、运营商客户业务支撑系统安全风险评估、以及工业控制系统安全风险评估等方面具备深厚的技术积累和丰富的实施经验。 </p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>信息安全保障体系设计规划咨询服务    对于客户基于风险评估结果和监管合规要求，提出的信息安全保障体系设计规划需求，向客户提供专业咨询服务，立足于客户信息处理设施和信息安全管理现状，依据信息安全相关的国际/国内标准规范、协助客户建立信息安全保障的建设目标，选择和组合信息安全控制措施，完成信息安全保障体系架构设计，并合理规划信息安全保障体系的建设步骤和资源投入，最终达成有序提升信息安全风险管控能力，保障信息系统安全运营的目标。  </p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>信息安全管理体系建设咨询服务    对于客户关于信息安全管理体系（ISMS）的建设和认证需求，向客户提供专业咨询服务，参照国际标准ISO27001、ISO27002、以及国内标准GB/T22080、GB/T22081，按照PDCA的完整管理过程，确定体系实施范围、实施安全风险评估、选择和实施安全控制措施、编写与制订文档化的体系文件、完成信息安全管理体系的导入运行、实施信息安全管理体系评审和内部审核、推荐和选择体系认证机构并配合完成体系认证审核、培训客户方人员通过内审员认证，从而确保客户建立和维护完整、有效的信息安全管理体系，为客户关键业务运营提供充足的安全保障。  </p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>重要信息系统信息安全等级保护合规设计与建设咨询服务    参照国家等级保护标准GB/T22239、GB/T22240及行业等级保护标准要求，向客户提供重要信息系统信息安全等级保护合规建设过程的专业咨询服务，协助客户完成系统定级和备案、信息安全技术和管理体系设计和实施、以及等级保护测评等工作，确保客户方重要信息系统符合国家和行业关于信息安全等级保护的监管要求，具备足够的信息安全保障能力。  </p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content" style="padding-bottom:3vw;">
                    <p>信息科技风险管理体系建设咨询服务    依据规范要求，协助客户建立起职责分工明确的三道防线信息科技治理架构，以及全面、合规、有效的信息科技风险管控体系，内容范围涵盖信息科技治理、信息科技风险管理、信息科技外包管理、信息科技业务连续性管理、信息安全管理、信息开发与项目建设管理、信息系统运行维护管理、以及信息科技审计监督管理等八方面。通过管理制度、流程、过程记录等正式文档化的成果交付，确保与信息科技风险管理有关的管理要求实现规范化和常态化落地执行，从而提升客户的信息科技风险管控能力，为客户关键业务持续稳定运营提供保障。</p>
                </div>
            </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       xxx: "\u3000\u3000",
      ClientWidth_number: document.documentElement.clientWidth,
    };
  },
  created() {
    this.getClientWidth();
   
  },
  methods: {
    getClientWidth() {
      window.onresize = () => {
        this.$parent.getClientWidth()
        this.ClientWidth_number = document.documentElement.clientWidth;
      };
    },
  },
};
</script>

<style lang='less' scoped>
@baseWidth: 1980;
.pxfix(@w) {
  width: (@w / @baseWidth) * 100vw;
}
 .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .container-body {
    .pxfix(1980);
    min-height: 700px;
    margin: 0 auto;
    background: #eff3f8;
    // margin-top: 20px;
    display: flex;
    flex: 1;
    .title{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: normal;
        font-family: "microsoft yahei";
        color: #555;
    }
    .content{
      margin: 0 1rem;
      color: #555;
      font-size:0.8rem; 
      line-height: 25px; 
      font:'microsoft yahei';
       }
    .body-box-phone{
         background: #fff;
           margin:1.5rem auto;
      }
    .body-box{
    .pxfix(1040);
     margin:1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
    }
    .body-box:hover{
     box-shadow: 0 0  10px #888888;
    }
  }
  .footer {
    line-height: 50px;
    background: #2f3034;
    width: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
</style>
